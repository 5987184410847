.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  .layout_body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    font-size: .14rem;
    >div {
      display: flex;
      min-width: 1200px;
      height: 100%;
      .content {
        flex: 1;
        width: 100%;
        min-width: 900px;
        overflow-x: hidden;
      }
    }
  }
}
