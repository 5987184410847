.app {
  text-align: center;
  color: #333333;
  height: 100%;
  .main {
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
}
