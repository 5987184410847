@aside_width: 2.4rem;

.aside {
  display: flex;
  width: @aside_width;
  min-width: @aside_width;
  height: 100%;
  text-align: left;
  font-size: .2rem;
  // user-select: none;
  overflow-y: auto;
  // padding-top: .2rem;
  >ul {
    width: 100%;
    border-right: 1px solid #e8e8e8;
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      line-height: .4rem;
      background: #ffffff;
      color: rgba(0,0,0,0.65);
      padding-left: .2rem;
      height: .56rem;
      cursor: pointer;
      // border-bottom: 1px solid #e6f7ff;
      span {
        margin-left: .2rem;
      }
      a {
        display: block;
        margin-left: .2rem;
        width: calc(100% - .2rem);
        color: #333333;
        text-decoration: none;
      }
      &.active {
       background: #e6f7ff;
       color: #1890ff; 
      }
      &:hover {
        color: #1890ff; 
        >a {
          color: #1890ff; 
        }
      }
      &.sub_menu {
        display: block;
        height: auto;
        .menu_hidden {
          display: none;
        }
        .parent {
          height: .56rem;
          line-height: .56rem;
        }
        ul {
          li {
            height: .4rem;
            a {
              margin-left: .4rem;
              font-size: .16rem;
            }
          }
        }
      }
      .icon-du {
        float: right;
        margin-top: .24rem;
        margin-right: .1rem;
        font-size: .12rem;
        font-weight: bold;
      }
    }
  }
}
