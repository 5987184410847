
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  // border: 0;
  // font-size: 100%;
  // font: inherit;
  // cursor: pointer;
  // vertical-align: baseline;
  // line-height: 1;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html,
body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
body {
  line-height: 1;
  font-size: 0.12rem;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol, ul {
  list-style: none;
}
h3,h4 {
  line-height: 1.2;
}
input,button{
  outline:none;
  -webkit-appearance:none;
  border:none;
  background:none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
@op-height: .5rem;
#root {
  height: 100%;
}
.ant-form-explain {
  text-align: left;
}
.main-content {
  // padding: .2rem;
  height: 100%;
  position: relative;
  overflow: hidden;
  .opreator {
    width: 100%;
    height: @op-height;
    line-height: @op-height;
    // position: absolute;
    // top: 0;
    // left: 0;
    text-align: right;
    padding: 0 .2rem;
    border-bottom: 1px solid #e8e8e8;
    button {
      font-weight: bold;
      margin-left: .2rem;
    }
  }
}


