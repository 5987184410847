.loading {
  width: 100%;
  height: 100%;
  padding-top: 0.2rem;
  font-size: 0.2rem;
}

.header {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.2rem;
  background-color: #40566F;
  color: #ffffff;
  font-size: 0.16rem;
  display: flex;
  justify-content: space-between;
}
.header .op {
  display: flex;
}
.header .op a {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.header .logo {
  font-size: 0.2rem;
}
.header .logo img {
  width: 0.4rem;
  border-radius: 0.2rem;
  margin-right: 0.15rem;
}

.footer {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  background: black;
  color: #ffffff;
  font-size: 0.14rem;
}

.aside {
  display: flex;
  width: 2.4rem;
  min-width: 2.4rem;
  height: 100%;
  text-align: left;
  font-size: 0.2rem;
  overflow-y: auto;
}
.aside > ul {
  width: 100%;
  border-right: 1px solid #e8e8e8;
  margin-bottom: 0;
}
.aside > ul li {
  display: flex;
  align-items: center;
  line-height: 0.4rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.65);
  padding-left: 0.2rem;
  height: 0.56rem;
  cursor: pointer;
}
.aside > ul li span {
  margin-left: 0.2rem;
}
.aside > ul li a {
  display: block;
  margin-left: 0.2rem;
  width: calc(100% - 0.2rem);
  color: #333333;
  text-decoration: none;
}
.aside > ul li.active {
  background: #e6f7ff;
  color: #1890ff;
}
.aside > ul li:hover {
  color: #1890ff;
}
.aside > ul li:hover > a {
  color: #1890ff;
}
.aside > ul li.sub_menu {
  display: block;
  height: auto;
}
.aside > ul li.sub_menu .menu_hidden {
  display: none;
}
.aside > ul li.sub_menu .parent {
  height: 0.56rem;
  line-height: 0.56rem;
}
.aside > ul li.sub_menu ul li {
  height: 0.4rem;
}
.aside > ul li.sub_menu ul li a {
  margin-left: 0.4rem;
  font-size: 0.16rem;
}
.aside > ul li .icon-du {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.1rem;
  font-size: 0.12rem;
  font-weight: bold;
}

.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.layout .layout_body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  font-size: 0.14rem;
}
.layout .layout_body > div {
  display: flex;
  min-width: 1200px;
  height: 100%;
}
.layout .layout_body > div .content {
  flex: 1 1;
  width: 100%;
  min-width: 900px;
  overflow-x: hidden;
}

.app {
  text-align: center;
  color: #333333;
  height: 100%;
}
.app .main {
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
body {
  line-height: 1;
  font-size: 0.12rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol,
ul {
  list-style: none;
}
h3,
h4 {
  line-height: 1.2;
}
input,
button {
  outline: none;
  -webkit-appearance: none;
  border: none;
  background: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
#root {
  height: 100%;
}
.ant-form-explain {
  text-align: left;
}
.main-content {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.main-content .opreator {
  width: 100%;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: right;
  padding: 0 0.2rem;
  border-bottom: 1px solid #e8e8e8;
}
.main-content .opreator button {
  font-weight: bold;
  margin-left: 0.2rem;
}

