.header {
  width: 100%;
  height: .5rem;
  line-height: .5rem;
  padding: 0 .2rem;
  //background: linear-gradient(left,  #F78E9D,#9933FF);
  background-color: #40566F;
  color: #ffffff;
  font-size: .16rem;
  display: flex;
  justify-content: space-between;
  .op {
    display: flex;
    a {
      margin-left: .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }
  .logo {
    // font-weight: bold;
    font-size: .2rem;
    img {
      width: .4rem;
      border-radius: .2rem;
      margin-right: .15rem;
    }
  }
}
